import React, { useEffect, useMemo, useRef } from 'react';
import { Table } from 'antd';

import { getObjectList, getUrlSearch, getUrlParams, getColumns, dictIsEqual } from "utils";
import { TableTop, Filter } from 'components/common';


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const getPagination = (model, dataSource) => {
  const offset = parseInt(model.filter.params.offset) || 0;
  const pageSize = model.pageSize < dataSource.length ? dataSource.length : model.pageSize;
  return { pageSize: pageSize, total: model.count, current: offset / pageSize + 1 };
}

export default ({ model, title, columnList, columnByName, dataSource, childrenColumnName, topActions, rowActions, selected, defaultFilter, activeFilter, location, showSearch, hideNav, filterFields, onLoadData }) => {
  const prevActiveFilter = usePrevious(activeFilter);

  /* eslint-disable react-hooks/exhaustive-deps */
  const columns = useMemo(
    () => getColumns(columnByName, columnList, model.filter.params.ordering),
    [columnList, model.filter.params.ordering]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const onChange = (pagination, filters, sorter) => {
    let params = getUrlParams(location.search);
    const order = o => o === 'descend' ? '-' : '';
    params.ordering = sorter.field ? `${order(sorter.order)}${sorter.field}` : '';
    params.offset = (pagination.current - 1) * pagination.pageSize;

    const query = `?${getUrlSearch(params)}`;
    onLoadData && onLoadData({ query, activeFilter });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const load = () => {
    if (!dataSource) {
      let query = model.filter.query;
      if (query === null) {
        if (!location.search) {
          query = `?${getUrlSearch(defaultFilter)}`;
        } else {
          query = location.search;
        }
      }
      onLoadData && onLoadData({ query, activeFilter });
    }
  };

  useEffect(() => {
    load();
  }, []);
  useEffect(() => {
    if (!dictIsEqual(activeFilter, prevActiveFilter)) {
      load();
    }
  }, [activeFilter]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const { loadListStatus, pageSize, count } = model;
  const _dataSource = dataSource ? dataSource : getObjectList(model);

  return (
    <div>
      <TableTop
        onLoadData={onLoadData}
        data={model}
        filterFields={filterFields}
        topActions={topActions}
        rowActions={rowActions}
        selected={selected}
        showSearch={showSearch}
      />

      <Table
        title={title}
        columns={columns}
        dataSource={_dataSource}
        rowKey="id"
        loading={loadListStatus.isLoading}
        pagination={hideNav ? false : getPagination(model, _dataSource)}
        onChange={onChange}
        childrenColumnName={childrenColumnName}
      />

      {!hideNav &&
      <div className="table-stat">Показано {pageSize} из {count}</div>}

      {filterFields &&
      <Filter onLoadData={onLoadData} data={model} fields={filterFields}/>}
    </div>
  );
};
