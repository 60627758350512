import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import Iframe from 'react-iframe'

import { Descriptions } from 'components/common';


const WebArchiveModal = ({ siteId, version, actions, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    console.log(version);
    actions.site.restore({ id: siteId, timestamp: version.timestamp });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onCancel();
    }, 1000);
  };

  if (!version) return null;

  return (
    <Modal
      title={`Архив: ${version.date} [html: ${version.html_count}; media: ${version.media_count}]`}
      visible={true}
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      width={1000}
      okText="Восстановить"
      cancelText="Отмена"
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <Descriptions column={1} gutter={[16, 16]}>
          <Descriptions.Item label="Ссылка на вебархив">
            <a href={version.url} target="_blank" rel="noopener noreferrer">{version.url}</a>
          </Descriptions.Item>
        </Descriptions>

        <Iframe
          url={version.url}
          width="952px"
          height="450px"
          id="myId"
          display="initial"
          position="relative"
        />
      </Spin>
    </Modal>
  );
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(WebArchiveModal);
