import { push, replace } from "connected-react-router";
import * as base from "./index";


export async function asyncRequest(dispatch, payload, rootState, apiRequest, actions, callback = null) {
  const token = rootState.auth.token;

  actions.request(payload);

  if (payload && payload.locationChange) {
    dispatch(replace({ search: payload.query === '?' ? '': payload.query }));
  }

  try {
    const { error, data, statusCode } = await apiRequest(token, payload);

    if (error) {
      actions.error({ data, statusCode });
    } else {
      actions.complete(data);
      if (callback) {
        await callback(data);
      }
    }
  } catch (error) {
    console.log('Request Error', error);
    actions.error()
  }
}


export async function asyncListLoadRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].listLoadRequest,
    complete: dispatch[model].listLoadComplete,
    error: dispatch[model].listLoadError,
  }, callback);
}


export async function asyncDetailLoadRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].detailLoadRequest,
    complete: dispatch[model].detailLoadComplete,
    error: dispatch[model].detailLoadError,
  }, callback);
}


export async function asyncUpdateRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].updateRequest,
    complete: dispatch[model].updateComplete,
    error: dispatch[model].updateError,
  }, async (data) => {
    if (callback) {
      await callback(data);
    } else {
      dispatch[model].showEditForm({ show: false });
      if (!payload.preventLoadDetail) {
        dispatch(push(rootState[model].urls.detail(data.id)));
      } else {
        const filter = rootState[model].filter;
        await dispatch[model].listLoad(filter);
      }
    }
  });
}

export async function asyncDeleteRequest(dispatch, payload, rootState, model, apiRequest, callback = null) {
  return base.asyncRequest(dispatch, payload, rootState, apiRequest, {
    request: dispatch[model].deleteRequest,
    complete: dispatch[model].deleteComplete,
    error: dispatch[model].deleteError,
  }, async (data) => {
    if (callback) {
      await callback(data);
    } else {
      dispatch(push(rootState[model].urls.list()));
      const filter = rootState[model].filter;
      await dispatch[model].listLoad(filter);
    }
  });
}
