import React from 'react';
import { Switch } from "antd";


export default ({ item, param, onChange, updateItemId, updateStatus, preventLoadDetail }) => {
  return (
    <Switch
      checkedChildren="вкл" unCheckedChildren="выкл"
      checked={item[param]}
      loading={updateStatus.isLoading && updateItemId === item.id}
      onClick={() => {
        const data = { id: item.id, [param]: !item[param], preventLoadDetail };
        onChange(data);
      }}
    />
  );
};
