import React from 'react';
import classNames from "classnames";

import styles from './fieldset.module.css';


export default ({ className, title, actions, children }) => {
  const withHeader = !!title || !!actions;

  const cx = classNames([
    styles.container,
    withHeader && styles.containerWithHeader,
    className,
  ]);

  return (
    <div className={cx}>
      {withHeader &&
      <div className={styles.header}>
        <div className={styles.headerMain}>
          <div className={styles.headerTitle}>{title}</div>
        </div>
        <div className={styles.headerActions}>{actions}</div>
      </div>}

      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

