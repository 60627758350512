import React, { useCallback } from 'react';
import { connect } from "react-redux";

import { tableColumn } from "utils";
import { TableList, ActionButton } from 'components/common';
import SiteValue from "../SiteValue";
import SiteStatus from "../SiteStatus";


const SiteList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, site }) => {
  const defaultColumnList = ['id', 'domain', 'status', 'actions'];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const columnByName = {
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 59,
    }),
    domain: tableColumn('Домен', 'domain', {
      render: (val, item) => <SiteValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    status: tableColumn('Статус', 'status', {
      render: (val, item) => <SiteStatus status={val}/>,
      sorter: true,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.site}
          actions={[{ name: 'delete' }]}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    if (activeFilter === undefined) {
      activeFilter = site.filter.activeFilter
    }
    actions.site.listLoad({ query, activeFilter, locationChange: true });
  }, [site, actions]);

  return (
    <TableList
      location={router.location}
      model={site}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
    />
  );
};

const mapState = state => ({
  router: state.router,
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteList);
