import React from 'react';
import { Input, Row, Col, Checkbox } from "antd";

import { FormItem } from 'utils';
import { Fieldset } from 'components/layout';
import { ServerSelect } from 'components/blocks/Server';
import { DomainSelect } from 'components/blocks/Domain';


export default (props) => {
  return (
    <div>
      <Fieldset className="form-block">
        <DomainSelect {...props} name="parent" label="Главный домен" required={false}/>

        <FormItem {...props} name="domain" label="Домен" required={true}>
          <Input size="large"/>
        </FormItem>

        <ServerSelect {...props} name="server" label="Сервер" required={false}/>
      </Fieldset>

      <Fieldset title="Регистратор" className="form-block">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...props} name="registrar" label="Регистратор" required={false}>
              <Input size="large"/>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...props} name="registrar_account" label="Логин" required={false}>
              <Input size="large"/>
            </FormItem>
          </Col>
        </Row>
      </Fieldset>

      <Fieldset title="NGINX" className="form-block">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem {...props} name="nginx_active" label="NGINX включен" required={false} valuePropName="checked">
              <Checkbox/>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem {...props} name="is_https" label="HTTPS" required={false} valuePropName="checked">
              <Checkbox/>
            </FormItem>
          </Col>
        </Row>
      </Fieldset>
    </div>
  );
};
