import React from 'react';
import { Form } from 'antd';


function getValidateStatus(fieldName, errorData) {
  if (fieldName in errorData) {
    let error = errorData[fieldName];
    if (error.non_field_errors) {
      error = error.non_field_errors;
    }
    return {
      validateStatus: "error",
      help: error,
    }
  }
  return {};
}

export const FormItem = props => {
  const { className, errorData, required, ...otherProps } = props;

  return (
    <Form.Item
      className={className}
      rules={[{ required: required, message: 'Обязательное поле' }]}
      {...getValidateStatus(otherProps.name, errorData || {})}
      {...otherProps}
    >
      {props.children}
    </Form.Item>
  )
};
