import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import { CloseCircleFilled, LockOutlined } from "@ant-design/icons";

import { tableColumn } from "utils";
import { TableList, ActionButton, DateTimeText, OnOff } from 'components/common';
import { ServerValue } from "components/blocks/Server";
import DomainValue from "../DomainValue";


const DomainList = ({ router, columnList, defaultFilter, activeFilter, topActions, actions, domain, dataSource }) => {
  const defaultColumnList = [
    'expand', 'id', 'domain', 'server', 'date_ended', 'is_blocked',
    'nginx_active', 'is_https', 'actions'
  ];
  if (!columnList) {
    columnList = defaultColumnList;
  }
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const columnByName = {
    expand: tableColumn('ID', 'id', {
      render: (val, item) => '',
      sorter: true,
      width: 80,
    }),
    id: tableColumn('ID', 'id', {
      sorter: true,
      width: 60,
    }),
    domain: tableColumn('Домен', 'domain', {
      render: (val, item) => <DomainValue detail={item} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    server: tableColumn('Сервер', 'server', {
      render: (val, item) => <ServerValue detail={val} asLink={true} isBack={true}/>,
      sorter: true,
    }),
    date_reg: tableColumn('Дата регистрации', 'date_reg', {
      render: (val, item) => <DateTimeText date={val} onlyDate={true}/>,
      width: 120,
      sorter: true,
    }),
    date_ended: tableColumn('Дата продления', 'date_ended', {
      render: (val, item) => <DateTimeText date={val} onlyDate={true}/>,
      width: 120,
      sorter: true,
    }),
    nginx_active: tableColumn('NGINX активен', 'nginx_active', {
      sorter: true,
      width: 150,
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="nginx_active"
          updateStatus={domain.updateStatus}
          preventLoadDetail={true}
          onChange={data => {
            setItemIdForUpdate(data.id);
            actions.domain.update(data);
          }}
        />
      ),
    }),
    is_https: tableColumn('HTTPS', 'is_https', {
      sorter: true,
      width: 150,
      render: (val, item) => (
        <OnOff
          item={item}
          updateItemId={itemIdForUpdate}
          param="is_https"
          updateStatus={domain.updateStatus}
          preventLoadDetail={true}
          onChange={data => {
            setItemIdForUpdate(data.id);
            actions.domain.update(data);
          }}
        />
      ),
    }),
    is_blocked: tableColumn('Заблокирован', 'is_blocked', {
      render: (val, item) => val && <CloseCircleFilled style={{ color: '#ff0000' }}/>,
      width: 100,
    }),
    actions: tableColumn('', 'actions', {
      align: 'right',
      render: (val, item) => (
        <ActionButton
          detail={item}
          modelActions={actions.domain}
          actions={[{ name: 'makeMain' }, { name: 'edit' }, { name: 'delete' }]}
          actionByName={{
            makeMain: (modelActions, item) => ({
              title: 'Сделать главным',
              icon: <LockOutlined/>,
              action: () => actions.domain.makeMain(item),
            }),
          }}
        />
      ),
    }),
  };

  const onLoad = useCallback(({ query, activeFilter }) => {
    let filter = (activeFilter === undefined) ?
      Object.assign({}, domain.filter.activeFilter) :
      Object.assign({}, activeFilter);
    actions.domain.listLoad({ query, activeFilter: filter, locationChange: true });
  }, [domain, actions]);

  return (
    <TableList
      location={router.location}
      model={domain}
      dataSource={dataSource}
      defaultFilter={defaultFilter}
      activeFilter={activeFilter}
      columnList={columnList}
      columnByName={columnByName}
      onLoadData={onLoad}
      topActions={topActions}
      showSearch={true}
      childrenColumnName='mirrors'
    />
  );
};

const mapState = state => ({
  router: state.router,
  domain: state.domain,
});

const mapDispatch = dispatch => ({
  actions: {
    domain: dispatch.domain,
  }
});

export default connect(mapState, mapDispatch)(DomainList);
