import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { domainDetailLoad } from "store/models/domain";
import { DataLoadComponent } from 'components/common';


const DomainValue = (props) => {
  return (
    <DataLoadComponent
      apiLoad={domainDetailLoad}
      mode="detail"
      {...props}
      render={({ id, item, items, linkPath }) => {
        const value = (item && item.domain) || id;

        return (
          <Fragment>
            {props.asLink && linkPath ?
              <Link to={linkPath}>{value}</Link> :
              <span>{value}</span>}
          </Fragment>
        );
      }}
    />
  );
};

const mapState = state => ({
  router: state.router,
  authToken: state.auth.token,
  model: state.domain,
});

export default connect(mapState)(DomainValue);
