import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const SiteForm = (props) => {
  const { showEditForm } = props.site;

  const onClose = () => {
    props.actions.site.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.site.update(data);
  };

  return (
    <Form
      title="Редактирование сайта"
      model={props.site}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteForm);

