import React from 'react';
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { FormItem } from 'utils';


export default (props) => {
  return (
    <div className="form-block">
      <FormItem
        {...props}
        name="mydrop_archive"
        label="Архив"
        required={false}
        valuePropName="fileList"
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e.slice(-1);
          }
          return e && e.fileList.slice(-1);
        }}
      >
        <Upload beforeUpload={() => false}>
          <Button>
            <UploadOutlined/> Загрузить
          </Button>
        </Upload>
      </FormItem>
    </div>
  );
};
