import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const MyDropArchiveUploadForm = (props) => {
  const { showMyDropArchiveUploadForm } = props.site;

  const onClose = () => {
    props.actions.site.showMyDropArchiveUploadForm({ show: false });
  };

  const onSubmit = data => {
    if (data.mydrop_archive && data.mydrop_archive.length > 0) {
      data.mydrop_archive = data.mydrop_archive[0].originFileObj;
    } else {
      data.mydrop_archive = '';
    }

    props.actions.site.update(data);
  };

  return (
    <Form
      title="Загрузка архива с mydrop.io"
      model={props.site}
      visible={showMyDropArchiveUploadForm}
      onClose={onClose}
      onSubmit={onSubmit}
      convertEditObject={editObject => {
        let mydrop_archive = [];
        if (editObject.mydrop_archive) {
          mydrop_archive = [{
            uid: '1',
            name: editObject.mydrop_archive.split('/').slice(-1)[0],
            status: 'done',
            url: editObject.mydrop_archive,
          }];
        }
        return { mydrop_archive }
      }}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(MyDropArchiveUploadForm);

