import React, { Fragment } from 'react';
import { Route } from 'react-router';

import SiteListScreen from './SiteListScreen';
import SiteDetailScreen from './SiteDetailScreen';


export default () => {
  return (
    <Fragment>
      <Route exact path="/site" component={SiteListScreen}/>
      <Route exact path="/site/:siteId" component={SiteDetailScreen}/>
      <Route exact path="/site/:siteId/backup" component={SiteDetailScreen}/>
    </Fragment>
  );
}
