import React  from 'react';
import classNames from 'classnames';

import { HeaderUserPanel } from 'components/blocks/User';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import HeaderActions from './HeaderActions';
import HeaderTabs from './HeaderTabs';
import './styles.css';


export default ({ title, breadcrumbs, modelActions, actions, actionByName, detail, basePath, tabs, onTabChange }) => {
  const cx = classNames({
    'header': true,
    'header--tabs': tabs && tabs.length > 0,
  });

  return (
    <header className={cx}>
      <div className="header-top">
        <div className="header-top-start">
          <HeaderBreadcrumbs breadcrumbs={breadcrumbs}/>
        </div>
        <div className="header-top-end">
          <HeaderUserPanel/>
        </div>
      </div>

      <div className="header-main">
        <div className="header-main-start">
          <div className="header-main-title">{title}</div>
        </div>

        <HeaderActions modelActions={modelActions} actions={actions} actionByName={actionByName} detail={detail}/>
      </div>

      {tabs &&
      <HeaderTabs
        basePath={basePath}
        tabs={tabs}
        onTabChange={onTabChange}
      />}
    </header>
  );
};
