import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';

import { siteGetStatus } from "store/models/site";
import { Descriptions } from "components/common";
import SiteStatus from "../SiteStatus";

const TIMEOUT_INTERVAL = 5000;


const SiteRestoreStatus = ({ authToken, id, defaultStatus, onGetStatus }) => {
  const [status, setStatus] = useState(defaultStatus);
  const [data, setData] = useState({});
  const [active, setActive] = useState(true);

  useEffect(() => {
    setStatus(defaultStatus);
  }, [defaultStatus]);

  useEffect(() => {
    let isActive = true;

    if (id && status !== SiteStatus.STATUS_NEW) {
      const loadData = () => {
        siteGetStatus(authToken, { id })
          .then(({ error, data }) => {
            if (isActive) {
              const _status = data ? data.status : status;
              if (data) {
                setData(data);
                if (data.status !== status) {
                  setStatus(data.status);
                  onGetStatus && onGetStatus(data);
                }
              }

              if (SiteStatus.isRestoring(_status)) {
                setTimeout(() => {
                  loadData();
                }, TIMEOUT_INTERVAL);
              } else {
                setActive(false);
              }
            }
          });
      }

      loadData();
    }
    return () => {
      isActive = false;
    }
  }, [id, status, authToken, onGetStatus]);

  console.log('status', data);

  if (status === SiteStatus.STATUS_NEW) return null;

  const duration = data.duration || '';

  const filesCount = data.files_count || 0;
  const downloadedCount = data.downloaded_count || 0;
  const errors = data.error_count ? `(ошибок: ${data.error_count })` : '';
  const percent = filesCount > 0 ? Math.round(downloadedCount / filesCount * 100) : 0;

  return (
    <Descriptions column={1} gutter={[16, 16]}>
      <Descriptions.Item label={`Процесс восстановления ${duration}`}>
        Восстановлено {downloadedCount} из {filesCount} {errors}
        <Progress percent={percent} status={active ? "active": "success"}/>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default SiteRestoreStatus
