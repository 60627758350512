const ENV = {
    'local': {
        base_url: "http://127.0.0.1:8000",
        site_url: "http://localhost:3000",
    },
    'production': {
        base_url: "https://drops-api.treeweb.top",
        site_url: "https://drops.treeweb.top",
    },
};

const urls = ENV['production'];

export const BASE_URL = urls.base_url;
export const SITE_URL = urls.site_url;
