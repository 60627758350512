import React from 'react';

import { ServerDetail } from 'components/blocks/Server';


export default (props) => {
  return (
    <ServerDetail/>
  )
};
