import { BASE_URL } from "config";
import { api, getHeaders } from "utils";
import * as base from '../base';
import { push } from "connected-react-router";


const endpoints = {
  list: `${BASE_URL}/api/v1/site/`,
  detail: (id) => `${BASE_URL}/api/v1/site/${id}/`,
  restore: (id) => `${BASE_URL}/api/v1/site/${id}/restore/`,
  publish: (id) => `${BASE_URL}/api/v1/site/${id}/publish/`,
  status: (id) => `${BASE_URL}/api/v1/site/${id}/status/`,
};

export const siteListLoad = (token, payload) => api.ListLoad(endpoints.list, token, payload);
export const siteDetailLoad = (token, { id }) => api.DetailLoad(endpoints.detail, token, { id });

export const siteUpdate = (token, data) => {
  let url = endpoints.list;
  let method = 'post';
  if (data.id) {
    url = endpoints.detail(data.id);
    method = 'put';
  }

  console.log('siteUpdate', data);
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  let headers = getHeaders(token);
  headers['Content-Type'] = 'multipart/form-data';

  return api.Load(method, url, formData, {
    headers: headers,
  });
}


export const siteDelete = (token, data) => api.Delete(endpoints.detail, token, data);
export const siteRestore = (token, data) => api.Load('put', endpoints.restore(data.id), data, {
  headers: getHeaders(token)
});
export const sitePublish = (token, data) => api.Load('put', endpoints.publish(data.id), {}, {
  headers: getHeaders(token)
});
export const siteGetStatus = (token, data) => api.Load('get', endpoints.status(data.id), {}, {
  headers: getHeaders(token)
});


export const site = {
  state: {
    ...base.ListState,
    ...base.DetailState,
    ...base.EditState,
    ...base.DeleteState,

    title: 'Сайты',
    urls: {
      list: () => `/site`,
      detail: (id) => `/site/${id}`,
    },

    showMyDropArchiveUploadForm: false,
  },
  reducers: {
    ...base.ListReducers,
    ...base.DetailReducers,
    ...base.EditReducers,
    ...base.DeleteReducers,

    showMyDropArchiveUploadForm(state, payload) {
      return {
        ...state,
        showMyDropArchiveUploadForm: payload.show,
        editObject: payload.object,
        editPreventLoadDetail: payload.preventLoadDetail,
      };
    },
  },
  effects: dispatch => ({
    async listLoad(payload, rootState) {
      await base.asyncListLoadRequest(dispatch, payload, rootState, 'site', siteListLoad)
    },

    async detailLoad(payload, rootState) {
      await base.asyncDetailLoadRequest(dispatch, payload, rootState, 'site', siteDetailLoad)
    },

    async update(payload, rootState) {
      await base.asyncUpdateRequest(dispatch, payload, rootState, 'site', siteUpdate,
        async (data) => {
          dispatch['site'].showEditForm({ show: false });
          dispatch['site'].showMyDropArchiveUploadForm({ show: false });
          if (!payload.preventLoadDetail) {
            dispatch(push(rootState['site'].urls.detail(data.id)));
          } else {
            const filter = rootState['site'].filter;
            await dispatch['site'].listLoad(filter);
          }
        });
    },

    async delete(payload, rootState) {
      await base.asyncDeleteRequest(dispatch, payload, rootState, 'site', siteDelete);
    },

    async restore(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, siteRestore, {
        request: dispatch['site'].updateRequest,
        complete: dispatch['site'].updateComplete,
        error: dispatch['site'].updateError,
      }, async (data) => {
        dispatch['site'].showEditForm({ show: false });
        if (!payload.preventLoadDetail) {
          const id = data.id;
          await dispatch['site'].detailLoad({ id });
          dispatch(push(rootState['site'].urls.detail(id)));
        } else {
          const filter = rootState['site'].filter;
          await dispatch['site'].listLoad(filter);
        }
      });
    },

    async publish(payload, rootState) {
      await base.asyncRequest(dispatch, payload, rootState, sitePublish, {
        request: dispatch['site'].updateRequest,
        complete: dispatch['site'].updateComplete,
        error: dispatch['site'].updateError,
      }, async (data) => {
        dispatch['site'].showEditForm({ show: false });
        if (!payload.preventLoadDetail) {
          const id = data.id;
          await dispatch['site'].detailLoad({ id });
          dispatch(push(rootState['site'].urls.detail(id)));
        } else {
          const filter = rootState['site'].filter;
          await dispatch['site'].listLoad(filter);
        }
      });
    },
  }),
}