import React from 'react';

import { UserDetail } from 'components/blocks/User';


export default (props) => {
  return (
    <UserDetail/>
  )
};
