import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router";

import { Page } from 'components/layout';
import { SiteForm, SiteStatus } from 'components/blocks/Site';
import DetailScreen from './DetailScreen';



const SiteDetailScreen = ({ history, site, actions, match }) => {
  useEffect(() => {
    actions.site.detailLoad({ id: match.params.siteId });
  }, [match.params.siteId, actions.site]);

  const id = match.params.siteId;
  const detail = site.detail || {};
  const domain = detail.domain || {};

  const defaultActions = [{
    mode: 'group',
    actions: [
      { mode: 'button', name: 'delete' },
    ]
  }];
  let headerActions = [];
  if (SiteStatus.isPublishReady(detail.status) || detail.mydrop_archive) {
    headerActions.push({ mode: 'button', name: 'publish' });
  }
  headerActions = headerActions.concat(defaultActions);

  return (
    <Page
      isLoading={site.loadDetailStatus.isLoading || site.updateStatus.isLoading}
      detail={detail}
      title={domain.domain}
      modelActions={actions.site}
      breadcrumbs={[
        { title: 'Сайты', path: site.urls.list() },
        { title: domain.domain, path: site.urls.detail(id) },
      ]}
      actionByName={{
        publish: (modelActions, item) => ({
          title: 'Опубликовать',
          action: () => actions.site.publish(item),
        }),
      }}
      actions={headerActions}
      basePath={site.urls.detail(id)}
    >
      <Route exact path="/site/:siteId" component={DetailScreen}/>

      <SiteForm/>
    </Page>
  );
};

const mapState = state => ({
  site: state.site,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteDetailScreen);
