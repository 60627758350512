import React  from 'react';

import { parseDate } from "utils";


export default ({ date, onlyDate }) => {
  let text = '';

  if (!date) {
    text = '-';
  } else {
    if (onlyDate) {
      text = parseDate(date).format('DD.MM.YYYY');
    } else {
      text = parseDate(date).format('DD.MM.YYYY HH:mm');
    }
  }

  return (
    <div>{text}</div>
  );
}
