import React from 'react';
import { connect } from 'react-redux';

import { Form } from "components/common";
import MainFieldSet from './MainFieldSet';


const ServerForm = (props) => {
  const { showEditForm } = props.server;

  const onClose = () => {
    props.actions.server.showEditForm({ show: false });
  };

  const onSubmit = data => {
    props.actions.server.update(data);
  };

  return (
    <Form
      title="Редактирование сервера"
      model={props.server}
      visible={showEditForm}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={{ port: 22, user: 'django', backup_dir: '/www' }}
    >
      <MainFieldSet {...props}/>
    </Form>
  )
};

const mapState = state => ({
  server: state.server,
});

const mapDispatch = dispatch => ({
  actions: {
    server: dispatch.server,
  }
});

export default connect(mapState, mapDispatch)(ServerForm);

