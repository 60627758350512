import React, { Fragment } from 'react';
import { Dropdown, Menu, Popconfirm } from 'antd';
import classNames from 'classnames';
import { MoreOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";


const DefaultActionByName = {
  add: (modelActions) => ({
    title: 'Добавить',
    icon: <PlusOutlined/>,
    action: () => modelActions.showEditForm({ show: true }),
  }),
  edit: (modelActions, item) => ({
    title: 'Редактировать',
    icon: <EditOutlined/>,
    action: () => modelActions.showEditForm({ show: true, object: item }),
  }),
  delete: (modelActions, item) => ({
    title: 'Удалить',
    icon: <DeleteOutlined/>,
    action: () => modelActions.delete(item),
    confirm: {
      title: 'Уверены, что хотите удалить?',
      okText: 'Удалить',
      cancelText: 'Отмена'
    }
  }),
};

const getActions = (actionName, actionByName) => {
  return Object.assign(DefaultActionByName, actionByName || {})[actionName];
}

const ActionButton = ({ className, modelActions, actions, actionByName, detail }) => {
  const cx = classNames([
    'action-button',
    className
  ]);

  const menu = (
    <Menu>
      {actions.map((item, index) => {
        const action = getActions(item.name, actionByName);
        if (!action) return null;
        const itemAction = action(modelActions, detail);
        const title = item.title || itemAction.title || '';

        return (
          <Menu.Item key={`item${index}`} onClick={!itemAction.confirm && itemAction.action}>
            {itemAction.confirm ?
              <Popconfirm
                placement="bottomRight"
                title={itemAction.confirm.title}
                okText={itemAction.confirm.okText}
                cancelText={itemAction.confirm.cancelText}
                onConfirm={itemAction.action}
                onClick={e => e.stopPropagation()}
                getPopupContainer={trigger => trigger.parentNode}
              >
                {itemAction.icon}{title}
              </Popconfirm>
              :
              <Fragment>
                {itemAction.icon}{title}
              </Fragment>
            }
          </Menu.Item>
        )
      })}
    </Menu>
  );

  return (
    <Dropdown className={cx} overlay={menu} trigger={['click']}>
      <MoreOutlined/>
    </Dropdown>
  );
};

ActionButton.getActions = getActions;

export default ActionButton;
