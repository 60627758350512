import React  from 'react';
import classNames from "classnames";

import styles from './status.module.css';

const STATUS_NEW = 0;

const STATUS_RESTORE_QUEUE = 11
const STATUS_RESTORE_PRECESS = 12;
const STATUS_RESTORE_COMPLETE = 19;

const STATUS_PUBLISH_QUEUE = 21
const STATUS_PUBLISH_PROCESS = 22;
const STATUS_PUBLISH_COMPLETE = 29;

const STATUS_ERROR_RESTORE = 91;
const STATUS_ERROR_PUBLISH = 92;


const STATUS = {
  [STATUS_NEW]: 'не восстановлен',
  [STATUS_RESTORE_QUEUE]: 'в очереди на восстановление',
  [STATUS_RESTORE_PRECESS]: 'в процессе восстановления',
  [STATUS_RESTORE_COMPLETE]: 'восстановлен',
  [STATUS_PUBLISH_QUEUE]: 'в очереди на публикацию',
  [STATUS_PUBLISH_PROCESS]: 'в процессе публикации',
  [STATUS_PUBLISH_COMPLETE]: 'опубликован',
  [STATUS_ERROR_RESTORE]: 'ошибка восстановления',
  [STATUS_ERROR_PUBLISH]: 'ошибка публикации',
};

const SiteStatus = ({ status, className, size }) => {
  const cx = classNames([
    styles.status,
    styles[`s${status}`],
    size && styles[`size--${size}`],
    className,
  ]);

  return (
    <div className={cx}>{STATUS[status] || status}</div>
  );
};

SiteStatus.STATUS_NEW = STATUS_NEW;
SiteStatus.STATUS_RESTORE_QUEUE = STATUS_RESTORE_QUEUE;
SiteStatus.STATUS_RESTORE_PRECESS = STATUS_RESTORE_PRECESS;
SiteStatus.STATUS_RESTORE_COMPLETE = STATUS_RESTORE_COMPLETE;
SiteStatus.STATUS_PUBLISH_QUEUE = STATUS_PUBLISH_QUEUE;
SiteStatus.STATUS_PUBLISH_PROCESS = STATUS_PUBLISH_PROCESS;
SiteStatus.STATUS_PUBLISH_COMPLETE = STATUS_PUBLISH_COMPLETE;
SiteStatus.STATUS_ERROR_RESTORE = STATUS_ERROR_RESTORE;
SiteStatus.STATUS_ERROR_PUBLISH = STATUS_ERROR_PUBLISH;
SiteStatus.STATUS = STATUS;

SiteStatus.isRestoring = (status) => {
  return [SiteStatus.STATUS_RESTORE_QUEUE, SiteStatus.STATUS_RESTORE_PRECESS].includes(status)
}

SiteStatus.isPublishReady = (status) => {
  return [SiteStatus.STATUS_RESTORE_COMPLETE, SiteStatus.STATUS_PUBLISH_PROCESS, SiteStatus.STATUS_ERROR_PUBLISH].includes(status);
}

export default SiteStatus;
