import React from 'react';

import { Page } from 'components/layout';


export default () => {
  return (
    <Page
      title="Рабочий стол"
      breadcrumbs={[]}
    />
  )
}
