import React from 'react';

import { DomainSelect } from 'components/blocks/Domain';


export default (props) => {
  return (
    <div className="form-block">
      <DomainSelect {...props} name="domain" label="Домен" required={true}/>
    </div>
  );
};
