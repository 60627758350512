import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { parseDate } from 'utils';
import { Fieldset } from 'components/layout';
import { Descriptions, DateTimeText, OnOff } from 'components/common';
import { ServerValue } from "components/blocks/Server";
import { CloseCircleFilled } from "@ant-design/icons";
import { DomainList } from "../index";


const DomainDetail = ({ domain, actions }) => {
  const detail = domain.detail || {};
  if (!detail.id) return null;

  const subDomainAdd = () => {
    actions.domain.showEditForm({ show: true, object: { parent: detail.id, server: detail.server } });
  };

  const itemUpdate = (data) => {
    actions.domain.update(data);
  };

  return (
    <Fragment>
      <Fieldset>
        <Descriptions column={3} gutter={[16, 16]}>
          <Descriptions.Item label="Домен" span={24}>{detail.domain}</Descriptions.Item>
          <Descriptions.Item label="Сервер" span={24}>
            <ServerValue detail={detail.server} asLink={true} isBack={true}/>
          </Descriptions.Item>
          <Descriptions.Item label="Дата регистрации"><DateTimeText date={detail.date_reg}/></Descriptions.Item>
          <Descriptions.Item label="Дата продления"><DateTimeText date={detail.date_ended}/></Descriptions.Item>

          <Descriptions.Item label="Заблокирован">
            {detail.is_blocked && <CloseCircleFilled style={{ color: '#ff0000' }}/>}
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Регистратор">
        <Descriptions column={2} gutter={[16, 16]}>
          <Descriptions.Item label="Регистратор">{detail.registrar}</Descriptions.Item>
          <Descriptions.Item label="Логин">{detail.registrar_account}</Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="NGINX">
        <Descriptions column={3} gutter={[16, 16]}>
          <Descriptions.Item label="NGINX включен">
            <OnOff
              item={detail}
              updateItemId={detail.id}
              param="nginx_active"
              updateStatus={domain.updateStatus}
              onChange={itemUpdate}
            />
          </Descriptions.Item>

          <Descriptions.Item label="HTTPS">
            <OnOff
              item={detail}
              updateItemId={detail.id}
              param="is_https"
              updateStatus={domain.updateStatus}
              onChange={itemUpdate}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Сертификат истекает"><DateTimeText date={detail.cert_expired}/></Descriptions.Item>
        </Descriptions>
      </Fieldset>

      {detail.block_records && detail.block_records.length > 0 &&
      <Fieldset title="История бликировок">
        <Table
          columns={[
            {
              title: 'Дата',
              dataIndex: 'date',
              key: 'date',
              width: 150,
              render: (text, item) => text && parseDate(text).format('DD.MM.YYYY'),
            }, {
              title: 'Гос. орган',
              dataIndex: 'gos_organ',
              key: 'gos_organ',
              width: 150,
            }, {
              title: 'Постановление',
              dataIndex: 'postanovlenie',
              key: 'postanovlenie',
              width: 150,
            }
          ]}
          dataSource={detail.block_records}
          pagination={false}
          rowKey="postanovlenie"
        />
      </Fieldset>}

      <Fieldset>
        <DomainList
          dataSource={detail.mirrors}
          activeFilter={{ parent: detail.id }}
          topActions={[
            <Button type="primary" icon={<PlusOutlined/>} onClick={subDomainAdd}>
              Добавить поддомен
            </Button>
          ]}
        />
      </Fieldset>
    </Fragment>
  );
};

const mapState = state => ({
  domain: state.domain,
});

const mapDispatch = dispatch => ({
  actions: {
    domain: dispatch.domain,
  }
});

export default connect(mapState, mapDispatch)(DomainDetail);
