import React from 'react';

import { SiteDetail } from 'components/blocks/Site';


export default (props) => {
  return (
    <SiteDetail/>
  )
};
