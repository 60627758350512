import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { Menu } from 'antd';
import { GlobalOutlined, ClusterOutlined, UserOutlined } from '@ant-design/icons';

import './styles.css';


class Navigation extends Component {
  navigate = ({ item, key, keyPath }) => {
    if (key === 'dashboard') {
      this.props.actions.router.push(`/`);
    } else {
      this.props.actions.router.push(`/${key}`);
    }
  };

  getCurrentRootNav() {
    const { pathname } = this.props.router.location;
    return pathname.split('/')[1] || 'dashboard';
  }

  render() {
    const currentRootNav = this.getCurrentRootNav();

    return (
      <Menu
        className="navigation"
        theme="dark"
        mode="inline"
        inlineIndent={16}
        defaultOpenKeys={[currentRootNav]}
        defaultSelectedKeys={[currentRootNav]}
        selectedKeys={[currentRootNav]}
        onClick={this.navigate}
      >
        <Menu.ItemGroup title="Сайты">
          <Menu.Item key="domain">
            <GlobalOutlined/>
            <span>Домены</span>
          </Menu.Item>

          <Menu.Item key="server">
            <ClusterOutlined/>
            <span>Сервера</span>
          </Menu.Item>

          <Menu.Item key="site">
            <ClusterOutlined/>
            <span>Сайты</span>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup title="Пользователи">
          <Menu.Item key="user">
            <UserOutlined/>
            <span>Пользователи</span>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  }
}

const mapState = state => ({
  router: state.router,
});

const mapDispatch = dispatch => ({
  actions: {
    router: {
      push: (payload) => dispatch(push(payload)),
    },
  }
});

export default connect(mapState, mapDispatch)(Navigation);
