import React from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';

import { Navigation } from 'components/layout';
import DashboardScreen from 'screens/DashboardScreen';
import UserScreen from 'screens/User';
import ServerScreen from 'screens/Server';
import DomainScreen from 'screens/Domain';
import SiteScreen from 'screens/Site';


export default () => {
  return (
    <Layout>
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="app-layout-sider"
      >
        <div className="logo"/>
        <Navigation/>
      </Layout.Sider>
      <Layout className="app-layout">
        <Switch>
          <Route exact path="/" component={DashboardScreen}/>

          <Route exact path="/user" component={UserScreen}/>
          <Route path="/user/:userId" component={UserScreen}/>

          <Route exact path="/server" component={ServerScreen}/>
          <Route path="/server/:serverId" component={ServerScreen}/>

          <Route exact path="/domain" component={DomainScreen}/>
          <Route path="/domain/:domainId" component={DomainScreen}/>

          <Route exact path="/site" component={SiteScreen}/>
          <Route path="/site/:siteId" component={SiteScreen}/>
        </Switch>
      </Layout>
    </Layout>
  );
};
