import React from 'react';

import { DomainDetail } from 'components/blocks/Domain';


export default (props) => {
  return (
    <DomainDetail/>
  )
};
