import React, { useEffect, useState } from 'react';

import { dictIsEqual } from "utils";
import { Spin } from "antd";


export default ({ mode, authToken, apiLoad, detailId, detail, filter, render, model, asLink, isBack, router }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);

  const [id, setId] = useState(null);
  const [listFilter, setListFilter] = useState(null);
  const [linkPath, setLinkPath] = useState(null);

  useEffect(() => {
    if (mode === 'detail') {
      if (detailId) {
        setId(detailId);
        const detailLoad = () => {
          setLoading(true);
          apiLoad(authToken, { id: detailId })
            .then(({ error, data }) => {
              setLoading(false);
              if (!error) {
                setId(data.id);
                setItem(data);
              }
            })
        };
        detailLoad();

      } else if (detail) {
        setId(detail.id);
        setItem(detail);
      }
    } else {
      setListFilter(listFilter);

      const listLoad = () => {
        setLoading(true);
        const activeFilter = Object.assign(filter || {}, { limit: 1000 });
        apiLoad(authToken, { activeFilter })
          .then(({ error, data }) => {
            setLoading(false);
            if (!error) {
              setItems(data.results);
            }
          })
      }
      listLoad();
    }

    if (asLink) {
      let state = { slideIn: true };
      if (isBack) {
        state.backUrl = router.location.pathname;
        state.backSearch = router.location.search
      }
      const path = { pathname: model.urls.detail(id), state: state };
      if (!dictIsEqual(linkPath, path)) {
        setLinkPath(path);
      }
    }
  }, [mode, detailId, detail, listFilter, asLink, router.location, isBack, filter, id, linkPath, model.urls, authToken, apiLoad]);

  return (
    <Spin spinning={loading}>
      {render({ id, item, items, linkPath })}
    </Spin>
  );
};
