import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, List, Button } from 'antd';
import { CheckCircleFilled } from "@ant-design/icons";

import { Fieldset } from 'components/layout';
import { Descriptions } from 'components/common';
import SiteStatus from "../SiteStatus";
import SiteRestoreStatus from '../SiteRestoreStatus';
import WebArchiveModal from '../WebArchiveModal';
import MyDropArchiveUploadForm from '../MyDropArchiveUploadForm';


const SiteDetail = ({ authToken, site, actions }) => {
  const [status, setStatus] = useState(0);
  const [archiveVersion, setArchiveVersion] = useState(null);

  const detail = site.detail || {};
  const domain = detail.domain || {};
  const archiveVersions = detail.timestamp_list || [];
  const mydropArchive = detail.mydrop_archive;

  useEffect(() => {
    setStatus(detail.status);
  }, [detail.status]);

  if (detail.id) {
    console.log(detail);
  }

  const onGetStatus = (data) => {
    if (detail.status !== data.status && data.status === SiteStatus.STATUS_RESTORE_COMPLETE) {
      actions.site.detailLoad({ id: detail.id })
    }
    setStatus(data.status);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Fieldset>
          <Descriptions column={2} gutter={[16, 16]}>
            <Descriptions.Item label="Домен" span={24}>{domain.domain}</Descriptions.Item>
            <Descriptions.Item label="Статус">
              <SiteStatus status={status}/>
            </Descriptions.Item>

            {detail.status !== SiteStatus.STATUS_RESTORE_COMPLETE &&
            <Descriptions.Item label="Архив mydrop.io">
              {mydropArchive &&
              <a href={mydropArchive} target="_blank" rel="noopener noreferrer" style={{marginRight: '10px'}}>
                {mydropArchive.split('/').slice(-1)[0]}
              </a>}

              <Button type="primary" size="small" onClick={() => {
                actions.site.showMyDropArchiveUploadForm({ show: true, object: detail })
              }}>
                Загрузить
              </Button>
            </Descriptions.Item>}

            <Descriptions.Item label="Админка" span={24}>
              {status === SiteStatus.STATUS_PUBLISH_COMPLETE &&
              <a href={`//${domain.domain}/cms.php`} target="_blank" rel="noopener noreferrer">
                {domain.domain}/cms.php
              </a>
              }
            </Descriptions.Item>
          </Descriptions>

          <SiteRestoreStatus
            authToken={authToken}
            id={detail.id}
            defaultStatus={status}
            onGetStatus={onGetStatus}
          />
        </Fieldset>

        <Fieldset title="Выбрать архив">
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={archiveVersions}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button key="list-loadmore-edit" size="small" onClick={() => {
                    setArchiveVersion(item);
                  }}>
                    смотреть
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={
                    <div>
                      {item.date}
                      {detail.archive_timestamp_restored === item.timestamp &&
                      <CheckCircleFilled style={{ marginLeft: '16px', color: '#00ff00' }}/>}
                    </div>
                  }
                />
                <div>html: {item.html_count}; media: {item.media_count}</div>
              </List.Item>
            )}
          />
        </Fieldset>

        <WebArchiveModal siteId={detail.id} version={archiveVersion} onCancel={() => setArchiveVersion(null)}/>

        <MyDropArchiveUploadForm/>
      </Col>
    </Row>
  );
};

const mapState = state => ({
  site: state.site,
  authToken: state.auth.token,
});

const mapDispatch = dispatch => ({
  actions: {
    site: dispatch.site,
  }
});

export default connect(mapState, mapDispatch)(SiteDetail);
