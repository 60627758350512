import React from 'react';

import { LoginForm } from 'components/blocks/Auth';
import styles from './screen.module.css';


export default () => {
  return (
    <div className={styles.main}>
      <div className={styles.panel}>
        <div>
          <div className={styles.title}>Вход</div>
          <LoginForm/>
        </div>
      </div>
    </div>
  );
}
